import i18next from 'i18next';
import { parseTimestamp } from '../utils/time';
import { PostNamazuGame } from '../utils/namazu';
import { setBlackListFromLocal } from '../utils';
import { websocketService } from './websocketService';
import { addRegionMessage,addTeamMessage,addTranslateMessage,blackUser } from '../store/modules/messageStore';

import store from '../store';

class GameService{
    enableNamazu = false;
    bindChannal = ['0','0','0','0'];
    namazuChannelList = [false,false,false]
    instance = null;
    filtersRegionList = []
    filtersTeamList = []
    filtersTranslateList = []
    blackList = []

    constructor() {
        if (!GameService.instance) {
            GameService.instance = this;
        }
        return GameService.instance;
    }

    setFiltersRegionList(filtersRegionList){
        this.filtersRegionList = filtersRegionList;
    }

    setFiltersTeamList(filtersTeamList){
        this.filtersTeamList = filtersTeamList;
    }

    setFiltersTranslateList(filtersTranslateList){
        this.filtersTranslateList = filtersTranslateList;
    }

    setBlackList(blackList){
        this.blackList = blackList;
    }

    addBlack(user){

        if (!this.blackList.some(black => black.userid === user.userid )) {
            this.blackList.push(user);
            setBlackListFromLocal(this.blackList)
            store.dispatch(blackUser(user));
            console.info("black: " + user)
        }
    }


    setEnableNamazu(enableNamazu){
        this.enableNamazu = enableNamazu;
    }
    
    setBingChannal(bingChannal){
        this.bindChannal = bingChannal;
    }

    setNamazuChannelList(channellist){
        this.namazuChannelList = channellist;
    }

    getEnableNamazu(){
        return this.enableNamazu;
    }

    getBindChannel(){
        return this.bindChannal;
    }

    getNamazuChannelList(){
        return this.namazuChannelList;
    }

    sendToRegion(element){
        console.info(element)
        console.info(this.blackList)
        if((!this.blackList.some(black => black.userid === element.userid))&& 
        (!this.filtersRegionList.some(filter => filter.isInclud !== containsIgnoreCase(element.content,filter.text)))) {
            store.dispatch(addRegionMessage(element));
            if(this.enableNamazu){
                var text = '/e ['+i18next.t("regionchannal") +  '] ';
                text +=  element.username + ':    ' + element.content
          
                PostNamazuGame(text)
            }
        }else{
            console.info("屏蔽" + element)
        }
    }
    sendToTeam(element){
        if((!this.blackList.some(black => black.userid === element.userid))&& 
        (!this.filtersTeamList.some(filter => filter.isInclud !== containsIgnoreCase(element.content,filter.text)))) {
            store.dispatch(addTeamMessage(element));

            if(this.enableNamazu){
                var text = '/e ['+i18next.t("teamupchannal") +  '] ';
                text +=  element.username + ':    ' + element.content
          
                PostNamazuGame(text)
            }
        }else{
            console.info("屏蔽" + element)
        }
    }
    sendToTranslate(element){
        if((!this.blackList.some(black => black.userid === element.userid))&& 
        (!this.filtersTranslateList.some(filter => filter.isInclud !== containsIgnoreCase(element.content,filter.text)))) {
            store.dispatch(addTranslateMessage(element));

            if(this.enableNamazu){
                var text = '/e ['+i18next.t("translatechannal") +  '] ';
                text +=  + element.username + ':    ' + element.content
          
                PostNamazuGame(text)
            }
        }else{
            console.info("屏蔽" + element)
        }
    }

    sendToServer(message,type){
    
            if((this.bindChannal[0] && this.bindChannal[0] !== '0' && this.bindChannal[0] == type)||
            (this.bindChannal[1] && this.bindChannal[1] !== '0' && this.bindChannal[1] == type)||
            (this.bindChannal[2] && this.bindChannal[2] !== '0' && this.bindChannal[2] == type)){

                websocketService.sendMessage(message,type)
                console.info(message + type);
            }
      
    }
}

function containsIgnoreCase(str, searchStr) {
    return str.toLowerCase().includes(searchStr.toLowerCase());
}

export const gameService = new GameService(); 