import './login.css'
import { useState,useEffect } from "react"
import { useTranslation } from "react-i18next";
import "../../i18n/I18n";
import { newAlert } from '../../utils/alert';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/modules/userStore';
import { loginAPI,registerAPI } from '../../apis/user';
import worldData from '../../world.json';
import { setLanguageFromLocal } from '../../utils';
import { getLastLoginNameFromLocal,setLastLoginNameFromLocal ,getIsLoginFromLocal,setIsLoginFromLocal} from '../../utils';

const regex = new RegExp(`^a-zA-Z0-9_\-\u4e00-\u9fa5`);

const Login = () =>{

    var username = '';
    var password = '';
    var langurageId;

    const { t, i18n } = useTranslation();

    const [isLogin, setLogin] = useState(true);
    const navigate = useNavigate()
    const dispatch = useDispatch();
 
    const [charName,setCharname] = useState('');
    const [serverName,setServerName] = useState('');



    useEffect(() => {
        startOverlayEvents();
        getLangurage()
      }, []); 
    
    setInterval(() => {
        getServer();
    }, 2000); 

   
    //getServer()
    addOverlayListener('ChangePrimaryPlayer', (data) => {
        console.info(data)
        console.info("charID: " + data.charID)
        console.info("charName: " + data.charName)
        setCharname(data.charName);
    });
    

    async function getLangurage(){
        const languageBrowser = navigator.language || navigator.userLanguage;
        console.log('browser language: ' + languageBrowser);
        if(languageBrowser === 'zh-CN'){
            setLanguageFromLocal('zh')
            i18n.changeLanguage('zh')
        }else{
            setLanguageFromLocal('en');
            i18n.changeLanguage('en')
        }

     
        let language = await callOverlayHandler({ call: 'getLanguage' });
        langurageId = language.languageId;
        if(langurageId == 5){
            setLanguageFromLocal('zh')
            i18n.changeLanguage('zh')
        }else{
            setLanguageFromLocal('en');
            i18n.changeLanguage('en')
        }
        console.log('act language: ' +  language.language, language.languageId);
    }
    
    async function getServer(){
        var data = await callOverlayHandler({ call: 'getCombatants'});
        if(data.combatants && data.combatants[0] ){
            if(data.combatants[0].Name === charName && data.combatants[0]){
                setServerName(data.combatants[0].WorldName);
            }
        }
     }

     

     async function loginClick(){
        if(!checkUsernameAndPassword()){
            return;
        }

        var user = {};
        user.username = username;
        user.password = password;
        console.log("login: ");
        console.log(user);


        const res = await loginAPI(JSON.stringify(user))
        console.info('getUser')
        if (res) {
            console.info(res.data)
            if(res.data.code == 200){
                dispatch(setUser(res.data.data));
                setLastLoginNameFromLocal(username)
                setIsLoginFromLocal(true)
                navigate('/')
            }else{
                newAlert(res.data.message)
            }
            
        }
    }

    async function registerClick(){
        if(charName =='' || serverName == ''){
            newAlert(t("firstLogin"));
            return false;
        }
        if(!checkUsernameAndPassword()){
            return;
        }

        var user = {};
        user.username = username;
        user.password = password;
        user.serverID = findKeyByWorldname(serverName);
        user.charName = charName;
        console.log("register: ");
        console.log(user);

        const s = (await registerAPI(JSON.stringify(user))).data
        console.info(s)
        if(s && s.code){
            if(s.code == 200){
                newAlert(s.data)
            }else{
                newAlert(s.message)
            }
        }
    }


    function checkUsernameAndPassword(){
        username = document.getElementById('username').value;
        password = document.getElementById('password').value;

        if(username == '' || password == ''){
            alert('emptyOfUsernameOrPassword');
            return false;
        }
        if(username.length > 15 || password.length > 15){
            alert('usernameOrPasswordToLong');
            return false;
        }
        if(regex.test(username) || regex.test(password)){
            alert('illegalOfUsernameOrPassword');
            return false;
        }
        return true;
    }

    function findKeyByWorldname(nameToFind) {
        // 使用 Object.keys 获取所有键的数组
        const keys = Object.keys(worldData);
      
        // 使用 find() 方法查找匹配的键
        const key = keys.find((key) => worldData[key].name === nameToFind || worldData[key].name_en === nameToFind);
    
        return key;
      }
    


    return (
        <div className="login-back">
          
        <div className="login-body">
            <div className="login-login">
            <div className="login-title">
                <h1 id="loginLabel" className={ isLogin===true ? 'login-labelSelected' : 'login-labelunSelected'} onClick={()=>{ !isLogin && setLogin(true)}}   >{t("login")}</h1>
                <h1 id="registerLabel" className={ isLogin===false ? 'login-labelSelected' : 'login-labelunSelected'} onClick={()=>{ isLogin && setLogin(false)}} >{t("register")}</h1>
            </div>


            <div>
                <label  className="login-label">{t("username")}</label>
                <input id="username" type="username" className="login-nameInput" placeholder="username" defaultValue={getLastLoginNameFromLocal()} />
            </div>
            <div>
                <label  className="login-label">{t("password")}</label>
                <input id="password" type="password" className="login-passwordInput" placeholder="password" />
            </div>
        
            {isLogin && <button id="loginButton" className="login-loginButton login-button" onClick={() =>{loginClick()}}>{t("login")}</button>}
            {!isLogin && <button id="registerButton" className="login-registerButton login-button" onClick={() =>{registerClick()}}>{t("register")}</button>}
          
    
        </div>

        {!isLogin && 
        <div className="login-player">
            <label >{t("registertext")}</label>
            <div>
                <label>{t("charname")}</label>
                <div id="name">{ charName }</div>
            </div>

            <div>
                <label>{t("servername")}</label>
                <div id="server">{ serverName }</div>
            </div>
    
        </div>}

        </div>

        <div className='login-beian'>
            <a className="login-beianlink" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index/" rel="noreferrer">浙ICP备2024087584号</a>
        </div>

        </div>
    )
}

export default Login