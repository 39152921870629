import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './about.css';
import { useTranslation } from "react-i18next";
import Issues from './Issues';
import Donate from './Donate';
import MainContent from './MainContent';
import Changelog from './Changelog'; // 新增 Changelog 组件

const About = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <div className="about-body">
            <div className="about-returnArrow" onClick={() => { navigate('/') }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 14l-4 -4l4 -4" />
                    <path d="M5 10h11a4 4 0 1 1 0 8h-1" />
                </svg>
            </div>

            <div className="about-navigation">
                <button 
                    className={`about-navButton ${location.pathname === '/about' ? 'active' : ''}`}
                    onClick={() => navigate('/about')}
                >
                    {t("about")}
                </button>
                <button 
                    className={`about-navButton ${location.pathname === '/about/issues' ? 'active' : ''}`}
                    onClick={() => navigate('/about/issues')}
                >
                    {t("issue")}
                </button>
                <button 
                    className={`about-navButton ${location.pathname === '/about/donate' ? 'active' : ''}`}
                    onClick={() => navigate('/about/donate')}
                >
                    {t("support")}
                </button>
                {/* <button 
                    className={`about-navButton ${location.pathname === '/about/changelog' ? 'active' : ''}`}
                    onClick={() => navigate('/about/changelog')}
                >
                    {t("changelog")}
                </button> */}
            </div>

            <div className="about-main">
                <Routes>
                    <Route path="/" element={<MainContent />} />
                    <Route path="issues" element={<Issues />} />
                    <Route path="donate" element={<Donate />} />
                    <Route path="changelog" element={<Changelog />} />
                </Routes>
            </div>
        </div>
    );
};

export default About;