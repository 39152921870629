import { createHashRouter } from 'react-router-dom'
import Index from '../pages/index'
import About from '../pages/about'
import Alert from '../pages/alert'
import Black from '../pages/black'
import Filters from '../pages/filters'
import Login from '../pages/login'
import Region from '../pages/index/region'
import Team from '../pages/index/team'
import Translate from '../pages/index/translate'
import Sponspors from '../pages/sponsors'
import Changelog from '../pages/about/Changelog'
import Settings from '../pages/settings'
import PrivateRoute from './PrivateRoute'
import Issues from '../pages/about/Issues'
import Donate from '../pages/about/Donate'
import MainContent from '../pages/about/MainContent'
import ChangePassword from '../pages/settings/ChangePassword'
import MainSetting from '../pages/settings/MainSetting'

const router = createHashRouter([
    // {
    //     index:true,
    //     element:<Login />
    // },
    {
        path: '/login',
        element:<Login />
    },
    {
        path:'/',
        element: (
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          ),
        children:[
            {
                index:true,
                element: <Region />
            },
            {
                path: 'region',
                element: <Region />
            },
            {
                path: 'team',
                element: <Team />
            },
            {
                path: 'translate',
                element: <Translate />
            }
        ]
    },    
    {
        path: '/about',
        element: <About />,
        children:[
            {
                index:true,
                element: <MainContent/>
            },
            {
                path: 'issues',
                element: <Issues/>
            },
            {
                path: 'donate',
                element: <Donate/>
            },
            {
                path: 'changelog',
                element: <Changelog/>
            }
        ]
    },
    {
        path: '/settings',
        element:<Settings/>,
        children:[
            {
                index:true,
                element: <MainSetting/>
            },
            {
                path: 'change-password',
                element: <ChangePassword/>
            },
        ]
    },
    {
        path: '/alert',
        element: <Alert />
    },
    {
        path: '/black',
        element: <Black />
    },
    {
        path: '/filters/:id',
        element: <Filters />
    },
    {
        path: '/sponsors',
        element:<Sponspors />
    },
])


export default router;