import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { newAlert } from '../utils/alert';
import store from '../store';
import { addUser,removeUser,addRegionMessage,addTeamMessage,addTranslateMessage } from '../store/modules/messageStore';
import { getOnlineUserAPI } from '../apis/msg';
import { getLanguageFromLocal,setLanguageFromLocal, setIsLoginFromLocal } from '../utils';
import { checkTokenAPI, postReportMsgAPI,refreshAPI } from '../apis/user';
import i18next from 'i18next';
import { gameService } from './gameService';
import { setUser } from '../store/modules/userStore';

class WebSocketService {
    client=null;
    connected=false;
    logined=false;
    reconnectAttempts = 0;
    maxReconnectAttempts = 5;
    reconnectDelay = 3000; // 3秒
    localuser = null;
    language = null;
    reconnectTimer = null;
    UUID = 0;

    
  constructor() {
    this.client = null;
    this.connected = false;
    this.logined = false;
    this.reconnectTimer = null;
  }

  connect(localuser,language) {

    if (this.connected) {
      return;
    }

    this.getLangurage()

    this.localuser = localuser.user; // 保存用户信息用于重连
    // this.language = language.language;
    // console.info(localuser)
    //console.info(language)

    if (!localuser || !localuser.user || !localuser.user.id) {
      this.clearReconnectTimer();
      toLogin()
    }
    this.initializeClient();
  }

  initializeClient() {
    console.info("connection language: "+this.language)

    this.client = new Client({

        webSocketFactory: () => new SockJS('https://ff14chat.com/ff14chat/msg/ws/'),
        // webSocketFactory: () => new SockJS('http://127.0.0.1:51601/msg/ws'),

        // connectHeaders: {
        //     Authorization: this.localuser.token
        // },
        // 添加心跳检测
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        // 重连相关配置
        reconnectDelay: this.reconnectDelay,
        onStompError: (frame) => {
            console.error('Broker reported error:', frame.headers['message']);
            console.error('Additional details:', frame.body);
            this.handleDisconnection();
        }
    });

    this.client.onConnect = (frame) => {

        console.log('Connect:', frame);
        this.connected = true;
        this.reconnectAttempts = 0; // 重置重连次数
        this.uuid = crypto.randomUUID();

        // 订阅各种频道...
        this.subscribeToChannels(this.uuid);
        
        // 连接成功后发送加入消息
        console.info("connect success, send join message");
        this.sendJoin();
    };

    this.client.onWebSocketClose = () => {
        console.log('WebSocket Connection Closed' + new Date());
        this.handleDisconnection();
    };

    this.client.onWebSocketError = (error) => {
        console.error('WebSocket Error:', error);
        this.handleDisconnection();
    };

    this.client.activate();
  }

  handleDisconnection() {
    this.connected = false;
    
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        console.log(`Attempting to reconnect... (${this.reconnectAttempts}/${this.maxReconnectAttempts})`);
        
        // 显示重连状态消息
        onSystemMessage(i18next.t('reconnecting') + `(${this.reconnectAttempts}/${this.maxReconnectAttempts})`);
        
        this.reconnectTimer = setTimeout(() => {
          if (!this.connected && this.localuser) {
              this.disconnect();
              this.initializeClient(this.localuser);
          }
      }, this.reconnectDelay);
    } else if(this.reconnectAttempts === this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        console.log('Max reconnection attempts reached');
        onSystemMessage(i18next.t('connectedFail'));
        this.clearReconnectTimer();
      //  toLogin();
    } else{
        console.log('Max reconnection attempts reached');
    }
  }
  clearReconnectTimer() {
    if (this.reconnectTimer) {
        clearTimeout(this.reconnectTimer); // 清除定时器
        this.reconnectTimer = null;
        console.log('Reconnect timer cleared');
    }
}

  disconnect() {
    if (this.client) {
      this.client.deactivate();
      this.connected = false;
      this.deactivate = true;
    }
  }

  subscribeToChannels() {
    // 订阅公共频道
    this.client.subscribe('/topic/public', (message) => {
        console.log('Received message:', JSON.parse(message.body));
    });

    // 订阅服务器频道
    this.client.subscribe('/partition/' + this.localuser.partitionId, (message) => {
        const messageData = JSON.parse(message.body);
        onPartitionMessage(messageData);
        //console.info("get partition message:", message.body);
    });

    // 订阅错误消息
    this.client.subscribe('/user/'+this.uuid+'/queue/errors', (message) => {
        console.info("get error message:", message.body);
        this.clearReconnectTimer();
        toLogin();
    });

    // 订阅警告消息
    this.client.subscribe('/user/'+this.uuid+'/queue/warns', (message) => {
        console.info("get warn message:", message.body);
        onSystemMessage(JSON.parse(message.body).error);
    });

    this.client.subscribe('/user/'+this.uuid+'/queue/system', (message) => {
      console.info("get warn message:", message.body);
      onSystemMessageList(JSON.parse(message.body));
  });
  }



  async sendJoin() {
    
    if (this.client && this.connected && !this.logined && this.localuser.token && this.localuser.serverId && this.localuser.id) {

        await this.ensureValidToken();

        const joinMessage = {
          type: 'JOIN',
          token: this.localuser.token,
          partitionId: this.localuser.partitionId,
          senderID: this.localuser.id,
          uuid: this.uuid,
          lang: this.language
        };
        
        this.client.publish({
            destination: "/app/chat.join",
            body: JSON.stringify(joinMessage),
          //   headers: {
          //     'lang': this.language || 'en'  // 使用当前语言设置或默认为英语
          // },
        });
        //console.info("sendJoin", JSON.stringify(joinMessage))
        getOnlineUsers(this.localuser.partitionId)
        console.info("JOIN Success!");


    } else {
        console.info("JOIN Failed!");
        this.clearReconnectTimer();
        toLogin();
    }
  }

  sendMessage(msg, channelID) {

    console.info("sendMessage", msg, channelID)
    if (this.client && this.connected) {
      const payload = { 
        type: 'CHAT', 
        content: msg, 
        channalID: channelID
      };
      
      this.client.publish({
        destination: '/app/chat.sendMessage',
        body: JSON.stringify(payload),
      });
    }
  }

  async ensureValidToken() {
    try {
      const checkTokenMessage = {
        token: this.localuser.token
      };
      const isTokenValid = (await checkTokenAPI(checkTokenMessage)).data;

      if (isTokenValid && isTokenValid.code !== 200) {
        console.info('refresh token')
        var refreshReq = {};
        refreshReq.refreshToken = this.localuser.refreshToken;

        const responseData = await refreshAPI(JSON.stringify(refreshReq))

        this.localuser = {
          ...this.localuser,
          refreshToken: responseData.data.data.refreshToken,
          token: responseData.data.data.accessToken
        };
        setUser(this.localuser)
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      window.location.href = '/login';
      throw error;
    }
  }

  reportMsg(text,msgID){
    toreportMsg(text,msgID)
  }

  getLangurage(){
    const languageLocal = getLanguageFromLocal()
    if(languageLocal){
      if(languageLocal === 'zh'){
        this.language = 'zh'
      }else{
        this.language = 'en'
      }
      return;
    }
   
    const languageBrowser = navigator.language || navigator.userLanguage;
    console.log('browser language: ' + languageBrowser);
    if(languageBrowser === 'zh-CN'){
        setLanguageFromLocal('zh')
        this.language = 'zh'
    }else{
        setLanguageFromLocal('en');
        this.language = 'en'
    }
  }
}

async function getOnlineUsers(partitionId){
    const response = (await getOnlineUserAPI(partitionId)).data;
    if(response.code === 200){
        const messageData = Object.entries(response.data);
        // console.info(messageData)
        // console.info(Object.keys(messageData).length)
        if(messageData && Object.keys(messageData).length > 0){
          messageData.forEach(([id, username]) => {
            store.dispatch(addUser({
              userid: id,
              username: username
            }));
            // console.log("id: " + id); 
            // console.log("username: " + username);  
          });
        }
    }
}

function onWarnsMessage(message){

  // 处理聊天消息
  const receivedMessage = {
    id: new Date().getTime(),
    content: message,
    timestamp: new Date().getTime(),
    userid: 0,
    username: 'Administrator',
    isAdmin: true,
    isWarn: true
  };

    store.dispatch(addRegionMessage(receivedMessage));
    store.dispatch(addTeamMessage(receivedMessage));
    store.dispatch(addTranslateMessage(receivedMessage));
    console.warn('receive warn message:', message);
}

function onSystemMessage(message){

  // 处理聊天消息
  const receivedMessage = {
    id: new Date().getTime(),
    content: message,
    timestamp: new Date().getTime(),
    userid: 0,
    username: 'Administrator',
    isAdmin: true,
    isWarn: true
  };
  store.dispatch(addRegionMessage(receivedMessage));
  store.dispatch(addTeamMessage(receivedMessage));
  store.dispatch(addTranslateMessage(receivedMessage));
  console.info('receive warn message:', message);
 
}

function onSystemMessageList(messageData){
      if(messageData && Object.keys(messageData).length > 0){
        messageData.forEach((message) => {
          const receivedMessage = {
            id: message.id,
            content: message.message,
            timestamp: new Date().getTime(),
            userid: 0,
            username: 'Administrator',
            isAdmin: true,
            isWarn: false
          };
          switch(message.type){
            case '1':
              store.dispatch(addRegionMessage(receivedMessage));
              break;
            case '2':
              store.dispatch(addTeamMessage(receivedMessage));
              break;
            case '3':
              store.dispatch(addTranslateMessage(receivedMessage));
              break;
          }
        });
      }
}

   
function onPartitionMessage(message){
  try {

    switch (message.type) {
        case 'JOIN':
            // 处理新用户加入
            store.dispatch(addUser({
                userid: message.userid,
                username: message.username
            }));
            break;
            
        case 'LEAVE':
            // 处理用户离开
            store.dispatch(removeUser(message.userid));
            break;
            
        case 'CHAT':
            // 处理聊天消息
            const receivedMessage = {
              id: message.id,
              content: message.content,
              timestamp: message.timestamp,
              userid: message.userid,
              username: message.username
            };

            switch (message.channalID){
              case '1':
                gameService.sendToRegion(receivedMessage)
                break;
              case '2':
                gameService.sendToTeam(receivedMessage)
                break;
              case '3':
                gameService.sendToTranslate(receivedMessage)
                break;
              default:
                console.warn('Unknown message channalID:', message.channalID);
              }
            break;
            
        default:
            console.warn('Unknown message type:', message.type);
      }
  } catch (error) {
      console.error('Error processing partition message:', error);
  }
}


function toLogin(){
  setIsLoginFromLocal(false)
  newAlert('The current account login has expired, please log in again \n 当前账号登录过期，请重新登录');
  window.location.href = '#/login';
}

async function toreportMsg(text,msgID) {
  var result = window.confirm(i18next.t('confirmReport') + "\"" + text+ "\"?");

  
  if (result) {
      if(msgID == 0){
        return
      }
      const report = {
        id: msgID
      };
      const response = (await postReportMsgAPI(JSON.stringify(report))).data
      console.info(response);
      if(response.code === 200){
        onSystemMessage(response.data)
      }else{
        onSystemMessage(response.message)
      }
  }
}




export const websocketService = new WebSocketService(); 