import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { changePasswordAPI } from '../../apis/user';
import "./index";
import { newAlert } from '../../utils/alert';


const ChangePassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = async () => {
        try {
            const response = (await changePasswordAPI({
                oldPassword: oldPassword,
                newPassword: newPassword
            })).data;

            if(response && response.code){
                if (response.code === 200) {
                    navigate('/login');
                    console.info("passwordChangeSuccess")
                } else {
                    newAlert(t("passwordChangeError") + response.message)
                }
            }else{
                newAlert(t("passwordChangeError"))
            }

        
         
        } catch (error) {
            console.error('Failed to change password:', error);
            newAlert(t("passwordChangeError"))
        }
    };

    return (
        <div className="change-password-body settings-titleArea about-labelTitle">
            <div className="about-returnArrow" onClick={() => navigate('/settings')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M9 14l-4 -4l4 -4" />
                    <path d="M5 10h11a4 4 0 1 1 0 8h-1" />
                </svg>
            </div>

            <div className="change-password-container">
                <h2 className="center">{t("changePassword")}</h2>
                <div className="password-input-group">
                    <label className="password-label">{t("oldPassword")}</label>
                    <input
                        type="password"
                        placeholder={t("oldPassword")}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <div className="password-input-group">
                    <label className="password-label">{t("newPassword")}</label>
                    <input
                        type="password"
                        placeholder={t("newPassword")}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                
                <button onClick={handleSubmit}>{t("submit")}</button>
            </div>
        </div>
    );
};

export default ChangePassword;