const LASTLOGINNAME = 'lastLoginName'
const autologin = 'autoLogin'

function setLastLoginNameFromLocal(name){
    localStorage.setItem(LASTLOGINNAME,name)
}

function setIsLoginFromLocal(flag){
    localStorage.setItem(autologin,flag)
}

function getLastLoginNameFromLocal(){
    const name = localStorage.getItem(LASTLOGINNAME)
    return name
}

function getIsLoginFromLocal(){
    return localStorage.getItem(autologin)
}

function removeLastLoginNameFromLocal(){
    localStorage.removeItem(LASTLOGINNAME)
}

function removeIsLoginFromLocal(){
    localStorage.removeItem(autologin)
}

export{
    setLastLoginNameFromLocal,
    getLastLoginNameFromLocal,
    removeLastLoginNameFromLocal,
    setIsLoginFromLocal,
    getIsLoginFromLocal,
    removeIsLoginFromLocal

}