import "./Point.css"


const Point = () =>{
    return (
        <div className="points-container">
          <div className="points-grid">
            <div className="point-row">
                <div className="point">•</div>
            </div>
            <div className="point-row">
                <div className="point">•</div>
                <div className="point">•</div>
            </div>
          </div>
        </div>
      );
}

export default Point