import { useNavigate, useOutletContext } from 'react-router-dom'
import { getFiltersFromLocal,setFiltersFromLocal } from '../../../utils/index';
import { useState,useEffect,useRef,useContext } from "react"
import { useTranslation } from "react-i18next";
import "../../../i18n/I18n";
import { parseTimestamp } from '../../../utils/time';
import { LocalMessages } from '..';
import { websocketService } from '../../../services/websocketService';
import { gameService } from '../../../services/gameService';



const Team = () =>{
    const [filtersList,setFiltersList]  = useState([]);
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [refresh,SetRefresh] = useState(0);
    const { setType } = useOutletContext();

    const messages = useContext(LocalMessages)
    const team = messages.team || []

    const messageTeamRef = useRef(null);

    useEffect(() => {
        setType(2);
    }, [setType]); 

    useEffect(() => {
        if (messageTeamRef.current) {
            messageTeamRef.current.scrollTop = messageTeamRef.current.scrollHeight;
        }
      }, [team]);

    useEffect(() => {

        //加载filters
        const filtersObject = getFiltersFromLocal()
        if(filtersObject && filtersObject.team){
            setFiltersList(filtersObject.team )
        }
    },[]); 


    const ListItem = ({ item }) => (
        <div className = "filter" id={item.id} onClick={removeItemWithId}> 
        { !item.isInclud && <span className="filterSpan">{t("Exclude") +" " }</span> }
        <span className="filter-content">{item.text}</span>
      <span className="filter-divider">|</span>
      <span className="filter-close">
      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
      </span>
       </div>
    );

    function removeItemWithId(e) {
        const index = filtersList.findIndex(item => item.id == e.currentTarget.id);
    
        if(index !== -1) {
            filtersList.splice(index, 1);
            setFiltersList(filtersList)
            SetRefresh(refresh+1)
            const filtersObject = getFiltersFromLocal() || {}
            filtersObject.team =filtersList
            gameService.setFiltersTeamList(filtersList)
            setFiltersFromLocal(filtersObject)
        } else {
            console.log('未找到 ID 为 ' + e.currentTarget.id + ' 的对象');
        }
    }



    const ListMsg = ({ item }) => (
    
        item.isAdmin 
        
        ?
        (    item.isWarn?
            <div className='message warn-user'>
            <div className = "parent-user" > 
                    <div>
                        {item.content.split('\n').map((paragraph, index) => (
                            <p key={index} className="content-paragraph">
                                {paragraph}
                            </p>
                        ))}
                    </div>
            </div>
        </div>
        :    
        <div className='message system-user'>
            <div className = "parent-user" > 
                    <div>
                        {item.content.split('\n').map((paragraph, index) => (
                            <p key={index} className="content-paragraph">
                                {paragraph}
                            </p>
                        ))}
                    </div>
            </div>
        </div>
        )
    
        :
        <div>
            <div className='message'>
                <div className = "parent-user" > 
                    <span className='poster poster-shadow'>{parseTimestamp(item.timestamp) + ' ' + item.username + ':    '}</span>
                    <span className='poster'>{item.content}</span>
                </div>
                <div className='right-parent'>
                    <div className='report' onClick={()=>{websocketService.reportMsg('用户'+item.username +' 的言论: '+item.content,item.id)}}>⚠</div>
                    <div className='black-user' onClick={()=>{blackConfirm(item.username ,item.userid);}}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-eye-off">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                            <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                            <path d="M3 3l18 18" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );

    function blackConfirm(username,userid) {
        var result = window.confirm("是否要屏蔽用户 " + username + " ?");
        if (result) {
            var blackuser = {}
            blackuser.userid = userid;
            blackuser.username = username;

            gameService.addBlack(blackuser)
            SetRefresh(refresh+1)
        }
    }


    return (         
        <div className="chat-container"> 

        <div className="filters" id="regionFilter">
            <svg id="regionFilterIcon" onClick={()=>{navigate('/filters/2')}} xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="var(--grey-blackBackGround-1)"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-search filterIcon">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                <path d="M21 21l-6 -6" />
            </svg>
            {filtersList.map(item => (
                    <ListItem key={item.id} item={item} />
            ))}
        </div>
        <div className="messageBoard" id="regionMessage" ref={messageTeamRef}>
                {
                 team.filter(item =>  true)
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .map(item => (
                    <ListMsg key={item.id} item={item} />
                ))}
        </div>
  </div>
)
}

export default Team