import { request } from "../utils";

const donateUrl = 'user/donate/v1/';
// const donateUrl = 'donate/v1/';



function getDonate(json){
    console.info('getDonate')

    return request({
        url: donateUrl,
        method: 'GET',
        data: json,
    })
}

export{ getDonate }