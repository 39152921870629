import './settings.css'
import { useNavigate,Routes, Route } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import ChangePassword from './ChangePassword';
import MainSetting from './MainSetting';

const Settings = () =>{

    const navigate = useNavigate()
    const { t } = useTranslation();

    return (
        <div className="about-body">
            <div className="about-returnArrow" onClick={()=>{navigate('/')}}>
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" /></svg>
            </div>


            <div className="about-label" >
                <div className= "about-labelTitle">{t("settings")}</div>
            </div>
            <div className="settings-main">
                <Routes>
                    <Route path="/" element={<MainSetting />} />
                    <Route path="change-password" element={<ChangePassword />} />
                </Routes>
            </div>
       
    </div>
    )
}

export default Settings