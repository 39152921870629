const ENABLEINPUT = 'enable_input'

function setEnableInputFromLocal(flag){
    localStorage.setItem(ENABLEINPUT,JSON.stringify(flag))
}

function getEnableInputFromLocal(){
    const flag = localStorage.getItem(ENABLEINPUT)
    return flag
}

function removeEnableInputFromLocal(){
    localStorage.removeItem(ENABLEINPUT)
}

export{
    setEnableInputFromLocal,
    getEnableInputFromLocal,
    removeEnableInputFromLocal
}