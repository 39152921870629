import { Outlet, useOutletContext } from "react-router-dom"
import { useEffect,React,useState,useContext } from "react"


function ChatWindow({ inputOpen, setInputOpen, setType }) {

    return (
        <div className="chatPage" style={{ height: !inputOpen ? '90%' : '85%' }}>
            <Outlet context={{ setType }} />
        </div>
    )
}


export default ChatWindow