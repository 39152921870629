import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const MainContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="about-content">
            <div className="about-logo">
                <div className="about-logo2">
                    <img className="about-logoImg" src="./ff14chat.png" alt="logo" />
                </div>
                
            </div>

            <div className="about-titleArea about-labelTitle">
                <div>{t("version")}</div>
                <div> 1.0.0</div>
                <div className="about-space">|</div>
                <button 
                    className="about-link-button"
                    onClick={() => navigate('/about/changelog')}
                >
                    {t("changelog")}
                </button>
            </div>
        </div>
    );
};

export default MainContent;