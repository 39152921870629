import { request } from "../utils";


const loginPath = 'user/user/v1/login';
const registrPath = 'user/user/v1/register';
const changeCharnamePath = 'user/user/v1/changeCharName';
const changeUsernamePath = 'user/user/v1/changeUserName';
const changePasswordPath = 'user/user/v1/changePassword';
const reportMsgPath = 'user/reportMsg/v1/';
const refreshPath = 'user/user/v1/refresh';
const checkTokenPath = 'msg/token/';



// const loginPath = 'user/v1/login';
// const registrPath = 'user/v1/register';
// const changeCharnamePath = 'user/v1/changeCharName';
// const changeUsernamePath = 'user/v1/changeUserName';
// const changePasswordPath = 'user/v1/changePassword';
// const reportMsgPath = 'reportMsg/v1/';


function postReportMsgAPI(data){
    return request({
        url: reportMsgPath,
        method: 'POST',
        data: data,
    })
}

function registerAPI(data){
    console.info('registerAPI')

    return request({
        url: registrPath,
        method: 'POST',
        data: data,
    })
}

function loginAPI(data){
    return request({
        url: loginPath,
        method: 'POST',
        data: data,
    })
}



function changeCharnameAPI(json){
    return request({
        url: changeCharnamePath,
        method: 'POST',
        data: json,
    })
}
// {
//     "oldName": "",
//     "newName": ""
//   }

function changeUsernameAPI(json){
    return request({
        url: changeUsernamePath,
        method: 'POST',
        data: json,
    })
}
// {
//     "oldName": "",
//     "newName": ""
//   }

function changePasswordAPI(json){
    return request({
        url: changePasswordPath,
        method: 'POST',
        data: json,
    })
}
// {
//     "oldPassword": "",
//     "newPassword": ""
//   }

function refreshAPI(json){
    return request({
        url: refreshPath,
        method: 'POST',
        data: json,
    })
}

function checkTokenAPI(json){
    return request({
        url: checkTokenPath,
        method: 'POST',
        data: json,
    })
}

export{ loginAPI,registerAPI,changeCharnameAPI,changeUsernameAPI,changePasswordAPI,postReportMsgAPI,refreshAPI,checkTokenAPI }