import { request } from "../utils";

const msgPath = 'msg/msg/v1/';
const systemMsgPath = 'msg/systemMsg/v1/system'
const getOnlineUsers = 'msg/onlineUser/';

function getOnlineUserAPI(partitionId){
    return request({
        url: getOnlineUsers + partitionId ,
        method: 'GET'
    })
}

function getSystemMsgAPI(){
    return request({
        url: systemMsgPath,
        method: 'GET'
    })
}

function postMsgAPI(json){
    return request({
        url: msgPath,
        method: 'POST',
        data: json,
    })
}




export{ getSystemMsgAPI, postMsgAPI,getOnlineUserAPI}