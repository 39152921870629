import { createSlice } from '@reduxjs/toolkit';


const messageSlice = createSlice({
  name: 'messages',
  initialState:{
      region: [],    // 区域聊天消息
      team: [],      // 队伍聊天消息
      translate: [], // 翻译聊天消息
      users: [],      // 用户列表
  },
  reducers: {
    addRegionMessage: (state, action) => {
      state.region.push(action.payload);
    },
    addTeamMessage: (state, action) => {
      state.team.push(action.payload);
    },
    addTranslateMessage: (state, action) => {
      state.translate.push(action.payload);
    },
    addUser: (state, action) => {
      const newUser = action.payload;
      const userExists = state.users.some(user => user.userid === newUser.userid);

      if (!userExists) {
        state.users.push(newUser);
      }
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(item => item.userId === action.payload.userId);
    },
    blackUser: (state,action) => { 
      state.region = state.region.filter(item => item.userid !== action.payload.userid)
      state.team = state.team.filter(item => item.userid !== action.payload.userid)
      state.translate = state.translate.filter(item => item.userid !== action.payload.userid)
    }
  }
});

export const { 
  addRegionMessage, 
  addTeamMessage, 
  addTranslateMessage, 
  addUser,
  removeUser ,
  blackUser
} = messageSlice.actions;

const messageReducer = messageSlice.reducer
export default messageReducer; 

