import { useEffect,useState } from "react"

import { getEnableNamazuFromLocal,getBindChannalFromLocal,getBlackListFromLocal,getFiltersFromLocal,getNamazuChannelFromLocal,getEnableInputFromLocal } from '../../utils/index';
import { gameService } from "../../services/gameService";


import UserList from "./UserList/UserList"
import './index.css'
import Navbar from './Navbar/Navbar'
import Inputwindow from "./Inputwindow";

import ChatWindow from "./chatwindow"
import Point from "./Point/Point";


const IndexGame = () =>{

    const [userListOpen, setuserListOpen] = useState(true);
    const [inputOpen, setInputOpen] = useState(true);
    const [type, setType] = useState(1);
    const [bindChannal ,setBindChannal] =useState(['0','0','0','0'])


    useEffect(() => {
        setInputOpen(getEnableInputFromLocal() === 'true' || getEnableInputFromLocal() === null || getEnableInputFromLocal() === undefined);
        
        const localBindChannal = getBindChannalFromLocal()
        if(localBindChannal){
            setBindChannal(localBindChannal)
            gameService.setBingChannal(localBindChannal)
        }

        const localEnableNamazu = getEnableNamazuFromLocal()
        if(localEnableNamazu){
            gameService.setEnableNamazu(localEnableNamazu)
        }

        const localNamazuList = getNamazuChannelFromLocal()
        if(localNamazuList){
            gameService.setNamazuChannelList(localNamazuList)
        }

        //加载filters
        const filtersObject = getFiltersFromLocal()
        if(filtersObject ){
            gameService.setFiltersRegionList(filtersObject.region)
            gameService.setFiltersTeamList(filtersObject.team)
            gameService.setFiltersTranslateList(filtersObject.translate)
        }

        //加载黑名单
        const blackObject = getBlackListFromLocal()
        if(blackObject){
            gameService.setBlackList(blackObject)
        }
    },[]); 

    //获取游戏发送消息
    useEffect(() => {
        console.info("开启监听")
        startOverlayEvents();
        addOverlayListener('LogLine', async (data) => {
            if(data.line.length === 6 && data.line[2] >= 10 && data.line[2]<=17){
                var type = data.line[2] - 9;
                var message = data.line[4];
                gameService.sendToServer(message,type)
            }
        });
    
    },[bindChannal]); 


    return (
        <div className="index">
            <div className="main" id="main" >
                <Navbar 
                    userListOpen={userListOpen} 
                    setuserListOpen={setuserListOpen} 
                    setType={setType}
                />
                <ChatWindow 
                    inputOpen={inputOpen} 
                    setInputOpen={setInputOpen} 
                    setType={setType}
                />
                {inputOpen && 
                    <Inputwindow 
                        inputOpen={inputOpen} 
                        setInputOpen={setInputOpen}
                        type={type}
                    />
                }
            </div>
            {userListOpen && <UserList />}
            <Point/>
        </div>
)}

export default IndexGame