import { createSlice } from "@reduxjs/toolkit";
import { getLanguageFromLocal, setLanguageFromLocal } from "../../utils";

const languageStore = createSlice({

    name: 'language',
    initialState:{
        language: getLanguageFromLocal() || ''
    },
    reducers:{
        setLanguage(state,action){
            state.language = action.payload
            setLanguageFromLocal(action.payload)
        }
    }
})

const getLanguage = () =>{

    return async (dispatch) =>{
        const res = localStorage.getItem('language')
    }
}

const {setLanguage} = languageStore.actions
const languageducer = languageStore.reducer
export { setLanguage,getLanguage }
export default languageducer