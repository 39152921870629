import './alert.css'
import { useSearchParams } from 'react-router-dom';
import { Fragment } from 'react';

const Alert = () =>{

    const [params] = useSearchParams()
    let data = decodeURIComponent(params.get('data'));  // 解码 URL 参数
    console.info(data)


    return (
    <div className="body">
         <div className="label" id="label">
            {data.split('\n').map((line, index) => (
            <Fragment key={index}>
            {line}
            <br />
            </Fragment>
        ))}
        </div>
    </div>)
}

export default Alert