import { request } from "../utils";

const issueUrl = 'user/issue/v1/';
// const issueUrl = 'issue/v1/';


function postIssue(json){
    console.info('postIssue')

    return request({
        url: issueUrl,
        method: 'POST',
        data: json,
    })
}

function getIssue(json){
    console.info('getIssue')

    return request({
        url: issueUrl,
        method: 'GET',
        data: json,
    })
}

export{ postIssue,getIssue }