import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getIssue, postIssue } from "../../apis/issue";
import { newAlert } from '../../utils/alert';

const Issues = () => {
    const [issues, setIssues] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        loadIssues();
    }, []);

    async function loadIssues() {
        try {
            const response = (await getIssue()).data;
            console.info(response)
            if (response.code === 200) {
                setIssues(response.data);
            }
        } catch (error) {
            console.error("Failed to load issues:", error);
            newAlert(t("Failed to load issues"));
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const text = event.target.issueText.value;
        if (text) {
            try {
                const res = (await postIssue(JSON.stringify({ text }))).data;
                if (res) {
                    //newAlert(res.message);
                    await loadIssues();
                    event.target.reset();
                }
            } catch (error) {
                console.error("Failed to submit issue:", error);
                //newAlert(t("Failed to submit issue"));
            }
        }
    }

    return (
            <div className="about-issues">
                <div className="about-issues-list">
                {issues
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt in descending order
    .map(issue => (
        <div key={issue.id} className="about-issue-item">
            <div className="about-issue-header">
                <span className="about-issue-date">
                    {new Date(issue.createdAt).toLocaleDateString()}
                </span>
                <span className={`about-issue-status ${issue.isSolve ? 'solved' : 'pending'}`}>
                    {issue.isSolve ? t("solved") : t("pending")}
                </span>
            </div>
            <div className="about-issue-text">{issue.text}</div>
            {issue.reply && (
                <div className="about-issue-reply">{issue.reply}</div>
            )}
        </div>
    ))}
                </div>

                <form onSubmit={handleSubmit} className="about-issue-form">
    <div className="about-issue-form-container">
        <textarea 
            name="issueText"
            className="about-input"
            placeholder={t("issue")}
            required
        />
        <button type="submit" className="about-saveButton">
            {t("submitissue")}
        </button>
    </div>
</form>
            </div>
    );
};

export default Issues;