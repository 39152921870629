import { useState } from "react"
import { useTranslation } from "react-i18next";
import "../../../i18n/I18n";
import { websocketService } from '../../../services/websocketService';

function Inputwindow(prop){
    const { t, i18n } = useTranslation();
    const [message, setMessage] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          sendMessage(message);
          setMessage('');
        }
      };
    
    const sendMessage = (msg) => {
        if (msg.trim() === '') {
            console.log('Cannot send an empty message');
            return;
        }

        websocketService.sendMessage(msg,prop.type);
    };


    return (
        <div className="input-container">
            <input 
                type="text" 
                className="chat-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={ 
                    prop.type===1 
                        ? t("inputtext1") 
                        : prop.type===2
                            ? t("inputtext2") 
                            : t("inputtext3")}
            />
        </div>
    )
}

export default Inputwindow