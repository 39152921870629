import axios from "axios";
import { getUserFromLocal } from "./localstorage/user";
import { getLanguageFromLocal } from "./localstorage/language";
import { refreshAPI } from "../apis/user";
import { setUser } from "../store/modules/userStore";


const request = axios.create({
    // baseURL: 'http://127.0.0.1/ff14chat/',
   baseURL: 'https://ff14chat.com/ff14chat/',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
})

request.interceptors.request.use((config) =>{
    if(config.method === 'post'){
        const user = getUserFromLocal();
        if(user && user.token){
            config.headers.Authorization = user.token;
        }
    }

    if(getLanguageFromLocal() ==='zh'){
        config.headers['Accept-Language'] = 'zh-CN';
    }else{
        config.headers['Accept-Language'] = 'en-US';
    }
    
    return config
},(error) => {
    return Promise.reject(error)
})


request.interceptors.response.use(
    async (response) => {
        const originalRequest = response.config;
    
        if (response.data && response.data.code === 401 ) {
            
            console.info("refresh token")
            try {
                var user = getUserFromLocal();
    
                var refreshReq = {};
                refreshReq.refreshToken = user.refreshToken;
    
                const responseData = await refreshAPI(JSON.stringify(refreshReq))

                user.refreshToken = responseData.data.data.refreshToken;
                user.token = responseData.data.data.accessToken;
                setUser(user)
      
                originalRequest.headers['Authorization'] = `${responseData.data.data.accessToken}`;
                return axios(originalRequest);
            } catch (refreshError) {
                console.error('刷新 token 失败', refreshError);
                // 刷新失败，跳转到登录页面
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
          }
    
        return response
    },
    (error) => {
        console.info(error)
        return error
    }
);


export { request }