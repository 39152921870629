import { configureStore } from "@reduxjs/toolkit";
import messageReducer from './modules/messageStore'
import userReducer from "./modules/userStore";
import languageducer from "./modules/language";

const store = configureStore({
    reducer:{
        messages: messageReducer,
        user: userReducer,
        language: languageducer,
    }
})

export default store