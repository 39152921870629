import { useTranslation } from "react-i18next";

const Changelog = () => {
    const { t } = useTranslation();

    return (
        <div className="about-changelog">
            <h2 className="about-changelog-title">{t("changelog")}</h2>
            <div className="about-changelog-list">
                <div className="about-changelog-item">
                    <div className="about-changelog-version">v1.0.0</div>
                    <div className="about-changelog-date">2025-01-01</div>
                    <ul className="about-changelog-changes">
                        <li>Initial release</li>
                    </ul>
                </div>

                <div className="about-changelog-item">
                    <div className="about-changelog-version">v1.0.1</div>
                    <div className="about-changelog-date">2025-01-06</div>
                    <ul className="about-changelog-changes">
                        <li>add change password</li>
                        <li>add get language from browser</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Changelog;