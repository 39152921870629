import { useEffect, React, useState, createContext } from "react"
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { websocketService } from '../../services/websocketService';
import IndexGame from "./indexGame"



const LocalUserContext = createContext('null')
const LocalMessages = createContext('null')
const LocalLanguage = createContext('null')
const Index = () => {
    const user = useSelector(state => state.user) // 替换 useState
    const messages = useSelector(state => state.messages)
    const language = useSelector(state => state.language)
    const navigate = useNavigate()

     useEffect(() => {

        console.info('Index 尝试连接websocket');
        try {
            if (!user) {
                navigate('/login')
                return;
            }
            if (!websocketService.connected) {
                websocketService.connect(user,language);
            } else {
                console.info("WebSocket service connected");
            }
        } catch (error) {
            console.error("Error in Index useEffect:", error);
        }
    }, [user]);


     console.info('Index 组件刷新');
    return (
        <LocalUserContext.Provider value={user}>
            <LocalMessages.Provider value={messages}>
                <LocalLanguage.Provider value={language}>
                    <IndexGame />
                </LocalLanguage.Provider>
            </LocalMessages.Provider>
        </LocalUserContext.Provider>
        
        
    );
}

export { LocalUserContext,LocalMessages,LocalLanguage }
export default Index;

