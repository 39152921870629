import { useTranslation } from "react-i18next";
import "../../../i18n/I18n";
import { useEffect,React,useState } from "react"
import { getusersAPI } from "../../../apis/msg"
import { useSelector } from 'react-redux';

const ListItem = ({ item }) => (
    <div className="user">{item.username}</div>
  );

function UserList({ style }){
    const { t } = useTranslation();
    const users = useSelector(state => state.messages.users);
    const sortedUsers = [...users].sort((a, b) => a.username.localeCompare(b.username));
    return (
      <div className="users" id="users" style = {style}>
          <div className = "user system-user">{t("online")}</div>
          <div className="htmlpage-row" id="usersOnline">
              
                <div>
                  {sortedUsers
                    // .sort((a, b) => a.username.localeCompare(b.username))  // 按 username 升序排序
                    .map(item => (
                      <ListItem key={item.userid} item={item} />  // 渲染每个 ListItem
                    ))}
                 
                </div>
          </div>
      </div>
    )
}

export default UserList

