import { Navigate } from 'react-router-dom';
import { getIsLoginFromLocal } from '../utils';

const isLoggedIn = () => {
  return getIsLoginFromLocal();
};

const PrivateRoute = ({ children }) => {
  const isLogin = isLoggedIn()
  console.info(isLogin)
  if (!isLogin) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default PrivateRoute;