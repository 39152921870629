import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getDonate } from "../../apis/donate";
import { newAlert } from '../../utils/alert';

const Donate = () => {
    const [donateInfo, setDonateInfo] = useState([]);
    const [activeQR, setActiveQR] = useState(null);
    const { t } = useTranslation();

    const wechatSVG = <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-wechat"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16.5 10c3.038 0 5.5 2.015 5.5 4.5c0 1.397 -.778 2.645 -2 3.47l0 2.03l-1.964 -1.178a6.649 6.649 0 0 1 -1.536 .178c-3.038 0 -5.5 -2.015 -5.5 -4.5s2.462 -4.5 5.5 -4.5z" /><path d="M11.197 15.698c-.69 .196 -1.43 .302 -2.197 .302a8.008 8.008 0 0 1 -2.612 -.432l-2.388 1.432v-2.801c-1.237 -1.082 -2 -2.564 -2 -4.199c0 -3.314 3.134 -6 7 -6c3.782 0 6.863 2.57 7 5.785l0 .233" /><path d="M10 8h.01" /><path d="M7 8h.01" /><path d="M15 14h.01" /><path d="M18 14h.01" /></svg>



    const paymentMethods = [
        { id: 'wechat', name: 'WeChat', icon: '/icon/brand-wechat.svg', qr: './wechat-qr.png' },
        { id: 'alipay', name: 'Alipay', icon: '/icon/brand-alipay.svg', qr: './alipay-qr.png' },
        { id: 'paypal', name: 'PayPal', icon: '/icon/brand-paypal.svg', qr: './paypal-qr.png' },
        { id: 'visa', name: 'Visa', icon: '/icon/brand-visa.svg', qr: './visa-qr.png' }
    ];

    useEffect(() => {
        loadDonateInfo();
    }, []);

    async function loadDonateInfo() {
        try {
            const response = (await getDonate()).data;
            if (response.code === 200) {
                setDonateInfo(response.data);
            }
        } catch (error) {
            console.error("Failed to load donate info:", error);
            newAlert(t("Failed to load donate info"));
        }
    }

    return (
        <div className="about-donate">
            <div className="about-donate-header">
                <h2>{t("supportTheProject")}</h2>
                {/* <p>{t("donate_description")}</p> */}
            </div>

            <div className="about-donate-methods">
                <div className="about-donate-method">
                    <div className="about-donate-method-title">
                        <i className="about-donate-icon">💖</i>
                        <h3>{t("choosePaymentMethod")}</h3>
                    </div>
                    <div className="about-donate-payment-options">
                        {paymentMethods.map((method) => (
                            <div 
                                key={method.id}
                                className="about-donate-payment-logo"
                                onMouseEnter={() => setActiveQR(method.id)}
                                onMouseLeave={() => setActiveQR(null)}
                            >
                                <img 
                                    src={method.icon} 
                                    alt={method.name} 
                                    title={method.name}
                                />
                                {activeQR === method.id && (
                                    <div className="about-donate-qr-popup">
                                        <img src={method.qr} alt={`${method.name} QR Code`} />
                                        <span className="about-donate-qr-label">
                                            {t(`Scan with ${method.name}`)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {donateInfo && donateInfo.length > 0 && (
                    <div className="about-donate-supporters">
                        <h3>{t("supporters")}</h3>
                        <div className="about-donate-supporters-list">
                            {donateInfo.map((supporter) => (
                                <div key={supporter.id} className="about-donate-supporter">
                                    <div className="about-donate-supporter-info">
                                        <span className="about-donate-supporter-name">
                                            {supporter.name}
                                        </span>
                                        <span className="about-donate-supporter-date">
                                            {new Date(supporter.createdAt).toLocaleDateString()}
                                        </span>
                                    </div>
                                    <div className="about-donate-supporter-details">
                                        <span className="about-donate-supporter-amount">
                                            {supporter.money} {supporter.unit}
                                        </span>
                                        {supporter.text && (
                                            <span className="about-donate-supporter-text">
                                                {supporter.text}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Donate;